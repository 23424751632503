<!--  邻里圈 -->
<template>
  <div class="">
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="信息管理">
        <information-management></information-management>
      </a-tab-pane>
      <a-tab-pane key="2" tab="话题分类">
        <topic-classification key="2"></topic-classification>
      </a-tab-pane>
      <a-tab-pane key="3" tab="黑名单">
        <blacklist></blacklist>
      </a-tab-pane>
      <a-tab-pane key="4" tab="敏感字屏蔽">
        <sensitive-words></sensitive-words>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import informationManagement from "./informationManagement/index.vue";
import topicClassification from "./topicClassification/index.vue";
import blacklist from "./blacklist/index.vue";
import sensitiveWords from "./sensitiveWords/index.vue";
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import <组件名称> from '<组件路径>';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { informationManagement, topicClassification, blacklist,sensitiveWords },
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.ant-tabs .ant-tabs-tab-active {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}
/deep/.ant-tabs .ant-tabs-tab:hover {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}
/deep/.ant-tabs .ant-tabs-ink-bar {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}
</style>